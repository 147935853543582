import '../styles/Footer.css'
import '../styles/Categorypage.css'
import Logo from "../assets/everydaynavigation_banner.png"
import TopMenu from "./TopMenu";
import Footer from "./Footer";
import {useLocation} from "react-router-dom";
import Content from "./Content";
import ArticleMainPage from "./ArticleMainPage";

const QueryResults = () => {
    const location = useLocation();
    const queryPosts = location.state.queryIds;
    const content = location.state.data;
    const query = location.state.querySearch;

    return (
        <div>
            <TopMenu back_image={Logo} content={content}/>
            <div className='category-title'>SEARCH RESULTS FOR: ' {query} '</div>
            <div>
                { queryPosts.length === 0 ?
                    <div className='query-result'>Sorry, but nothing matched your search terms. <br/> Please try again with some different keywords.</div>
                    :
                    <Content>
                        { queryPosts.map(function (key) {
                                return <ArticleMainPage key={key} id={content[key].id} category={content[key].category} title={content[key].title} content={content[key].content} image={content[key].image}/>
                        })}
                    </Content>
                }
            </div>
            <Footer/>
        </div>
    );
}

export default QueryResults